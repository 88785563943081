import "./content-listing.scss";

import { array, string } from "prop-types";

import ContentListingItem from "./ContentListingItem.jsx";

const propTypes = {
  title: string,
  description: string,
  contentItems: array.isRequired,
};

const ContentListing = (props) => {
  const { title, description, contentItems } = props;

  return (
    <section className="content-listing">
      <div className="content-listing__header">
        {title && <h2 className="content-listing__title"> {title}</h2>}
        {description && <p className="content-description"> {description}</p>}
      </div>
      <ul>
        {contentItems &&
          contentItems.map((item, i) => (
            <ContentListingItem key={i} {...item} />
          ))}
      </ul>
    </section>
  );
};

ContentListing.propTypes = propTypes;
export default ContentListing;

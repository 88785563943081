import "./content-listing-item.scss";

import * as R from "ramda";

import { node, object, string } from "prop-types";
import { useRef, useState } from "react";

import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import cx from "classnames";
import { navigate } from "gatsby";
import { useContentfulSiteMap } from "../../common/contentfulSiteMap.jsx";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {
  title: string.isRequired,
  text: node,
  image: node,
  link: object,
};

const ContentListingItem = (props) => {
  const { title, text, image, link } = props;

  const siteMap = useContentfulSiteMap();

  const handleClick = (link) => {
    const path = link.to || R.path([link.pageId, "path"])(siteMap);
    if (!R.isNil(path)) {
      // for external and media links, open a in a new window
      if (link.type === "external") {
        window.open(path, "_blank");
      }
      // for internal/page links, navigate regularly
      else {
        navigate(path);
      }
    }
  };

  const itemRef = useRef();
  const [entered, setEntered] = useState(false);

  useScrollama(itemRef, {
    offset: 0.7,
    // debug: true,
    onStepEnter: () => setEntered(true),
    once: true,
  });

  return (
    <li
      className={cx("content-listing-item", {
        "content-listing-item--entered": entered,
      })}
      ref={itemRef}
    >
      <div className="content-listing-item__wrapper">
        <div>
          {title && <div className="content-listing-item__title">{title}</div>}
          <div className="content-listing-item__description">
            {text}
            {link && (
              <ContentfulLink
                className="content-listing-item__link"
                {...link}
              />
            )}
          </div>
        </div>

        <div
          className="is-clickable"
          role="presentation"
          onClick={() => handleClick(link)}
        >
          {image && image}
        </div>
      </div>
    </li>
  );
};

ContentListingItem.propTypes = propTypes;
export default ContentListingItem;

import "./listing-page.scss";

import * as R from "ramda";

import { shape, string } from "prop-types";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb.jsx";
import Container100 from "../../components/Container/Container100.jsx";
import ContainerBleed from "../../components/Container/ContainerBleed.jsx";
import ContentListing from "../../components/ContentListing/ContentListing.jsx";
import EventsPage from "../../components/EventsPage/EventsPage";
import FourCardGrid from "../../components/FourCardGrid/FourCardGrid.jsx";
import Page from "../../components/Page/Page.jsx";
import PageBanner from "../../components/PageBanner/PageBanner.jsx";
import Promo from "../../components/Promo/Promo.jsx";
import SignUp from "../../components/SignUp/SignUp.jsx";
import SlideShowBlock from "../../components/SlideShow/SlideShowBlock.jsx";
import SubPageHero from "../../components/SubPageHero/SubPageHero.jsx";
import TwoUpTextBanner from "../../components/TwoUpTextBanner/TwoUpTextBanner.jsx";
import extractInfoBlock from "../../common/extractInfoBlock.js";
import extractPageBanner from "../../common/extractPageBanner.js";
import extractSlideShow from "../../common/extractSlideShow.js";
import extractSubPageHero from "../../common/extractSubPageHero.js";
import extractTag from "../../common/extractTag.js";
import extractTwoUpTextBanner from "../../common/extractTwoUpTextBanner.js";
import { graphql } from "gatsby";

const propTypes = { pageContext: shape({ id: string.isRequired }).isRequired };

const ListingPage = (props) => {
  const {
    pageContext: { id },
  } = props;

  const data = R.compose(
    R.applySpec({
      pageBanner: R.ifElse(
        R.pathEq(["listingPageHeader", "__typename"], "ContentfulPageBanner"),
        R.compose(extractPageBanner, R.prop("listingPageHeader")),
        () => null,
      ),
      subPageHero: R.ifElse(
        R.pathEq(["listingPageHeader", "__typename"], "ContentfulSubPageHero"),
        R.compose(extractSubPageHero, R.prop("listingPageHeader")),
        () => null,
      ),
      listingPageTwoUpTextBanner: R.prop("listingPageTwoUpTextBanner"),
      listingPageSlideShow: R.prop("listingPageSlideShow"),
      listingDisplay: R.prop("listingDisplay"),
    }),
    R.evolve({
      listingPageSlideShow: R.unless(R.isNil, extractSlideShow),
      listingPageTwoUpTextBanner: R.unless(R.isNil, extractTwoUpTextBanner),
    }),
    R.defaultTo({}),
    R.path(["data", "contentfulPage", "pageContent"]),
  )(props);

  const pageTitle = R.path(["data", "contentfulPage", "title"])(props);

  const pageInfo = R.compose(
    R.compose(R.unless(R.isNil, extractInfoBlock)),
    R.path(["data", "contentfulPage", "pageListingInfo"]),
  )(props);

  const tag = R.compose(
    R.unless(R.isNil, extractTag),
    R.path(["data", "contentfulPage", "pageTag"]),
  )(props);

  const listingInfoItems = R.compose(
    R.map(R.compose(extractInfoBlock, R.prop("pageListingInfo"))),
    //if a page has no listing info, remove it from the array
    R.filter(R.compose((data) => data !== null, R.prop("pageListingInfo"))),
    R.defaultTo([]),
    R.path(["data", "contentfulPage", "subPages"]),
  )(props);

  const {
    subPageHero,
    pageBanner,
    listingPageSlideShow,
    listingPageTwoUpTextBanner,
    listingDisplay,
  } = data;

  if(props.path ==='/take-action/events') console.log('YEAH');

  return (
    <>
    {props.path !== '/take-action/events'?(<Page
      pageTitle={pageTitle}
      pageDescription={
        subPageHero ? subPageHero?.subtitle : pageBanner?.description
      }
      className="listing-page"
    >
      <BreadCrumb pageId={id} />

      {subPageHero && <SubPageHero tag={tag} {...subPageHero} />}

      {pageBanner && <PageBanner {...pageBanner} />}

      {listingInfoItems && (
        <Container100 className="container--wider">
          {listingDisplay ? (
            <ContentListing
              title={pageInfo ? pageInfo.title : null}
              // title={"TITLE"}
              description={pageInfo ? pageInfo.text : null}
              // description={"DESCRIPTION"}
              contentItems={listingInfoItems}
            />
          ) : (
            <FourCardGrid
              title={pageInfo ? pageInfo.title : null}
              description={pageInfo ? pageInfo.text : null}
              cards={listingInfoItems.map((item, i) => (
                <Promo key={i} {...item} />
              ))}
            />
          )}
        </Container100>
      )}

      {listingPageSlideShow && (
        <ContainerBleed className="listing-page__slide-show-bleed container--has-background">
          <SlideShowBlock {...listingPageSlideShow} />
        </ContainerBleed>
      )}

      {listingPageTwoUpTextBanner && (
        <ContainerBleed>
          <TwoUpTextBanner {...listingPageTwoUpTextBanner} />
        </ContainerBleed>
      )}

      <ContainerBleed>
        <SignUp
          title="Join Our Movement"
          subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
        />
      </ContainerBleed>
    </Page>
   ):(  <EventsPage />)}
    </>
  );
};

ListingPage.propTypes = propTypes;
export default ListingPage;

export const query = graphql`
  query ListingPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...Page
    }
  }
`;

import "./four-card-grid.scss";

import * as R from "ramda";

import { arrayOf, object, string } from "prop-types";

import Button from "../Button/Button";
import Container100 from "../Container/Container100.jsx";
import PlusSigns from "../PlusSigns/PlusSigns.jsx";
import { useState } from "react";

const propTypes = {
  title: string,
  description: string,
  cards: arrayOf(object).isRequired,
};

const FourCardGrid = (props) => {
  const { title, description, cards } = props;

  //there are over 100 partners so split them into groups of 16
  const groupsOf16Cards = R.splitEvery(16, cards);
  const totalGroups = R.length(groupsOf16Cards);

  const [groupsToDisplay, setGroupsToDisplay] = useState(1);

  const displayedCards = R.unnest(
    groupsOf16Cards.filter((group, i) => i <= groupsToDisplay - 1),
  );

  return (
    <section className="four-card-grid">
      <Container100 className="container--wider">
        <PlusSigns />
        <div className="four-card-grid__header">
          {title && (
            <h2>
              <strong>{title}</strong>
            </h2>
          )}
          {description && (
            <div className="four-card-grid__description">{description}</div>
          )}
        </div>
        <div className="four-card-grid__cards">{displayedCards}</div>
        {groupsToDisplay < totalGroups && (
          <div className="four-card-grid__load-more">
            <Button
              className="btn--big"
              onClick={() => setGroupsToDisplay((prevState) => prevState + 1)}
              type="button"
            >
              Load More
            </Button>
          </div>
        )}
      </Container100>
    </section>
  );
};

FourCardGrid.propTypes = propTypes;
export default FourCardGrid;
